import { useNavigate } from "react-router-dom";
import classes from "./waiting-list.module.css";
import { WaitingListOriginal } from "../../components/waiting-list/original/waiting-list";
import { WaitingList } from "../../components/waiting-list/waiting-list";
import { useFetchBusiness } from "../../hooks/use-fetch-business";

export function WaitingListPage() {
  const navigate = useNavigate();
  const { data, error, loading } = useFetchBusiness();
  const businessType = data?.businessType || "original";

  if (error) {
    return <p>something went wrong!</p>;
  }

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        {loading ? (
          <p>Loading...</p>
        ) : businessType === "original" ? (
          <WaitingListOriginal onTimerEnd={() => navigate("/waiting-list")} />
        ) : (
          <WaitingList onTimerEnd={() => navigate("/waiting-list")} />
        )}
        {/* <WaitingList onTimerEnd={() => navigate("/waiting-list")} /> */}
      </div>
    </main>
  );
}
