import { HomePage } from "../pages/home/home";
import { LoginPaidPage } from "../pages/auth/login-paid";
import { WaitingListPage } from "../pages/waiting-list/waiting-list";
import { SharePage } from "../pages/share/share";
import { NameForm } from "../pages/name-form/name-form";
import { SendFileCopy } from "../pages/send-file-copy/send-file-copy";
import { AutoForm } from "../pages/auto/auto";
import { MessagePage } from "../pages/message/message";
import { ChatPage } from "../pages/chat/chat";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/:businessId",
    element: <LoginPaidPage />,
  },
  { path: "/waiting-list", element: <WaitingListPage /> },
  { path: "/share", element: <SharePage /> },
  { path: "/name-form/:phone", element: <NameForm /> },
  { path: "/send-file", element: <SendFileCopy /> },
  { path: "/auto", element: <AutoForm /> },
  { path: "/message", element: <MessagePage /> },
  { path: "/chat", element: <ChatPage /> },
];

export default routes;
