// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Checkbox */
.checkbox_aggreement_box__o3SNF {
  margin-block: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.checkbox_aggreement_box__o3SNF input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0.5rem;
  display: none;
  cursor: pointer;
}

.checkbox_aggreement_box__o3SNF label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.checkbox_aggreement_box__o3SNF label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ff8120;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox_aggreement_box__o3SNF input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 12px;
  width: 6px;
  height: 14px;
  border: solid #ff8120;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox_aggreement_box__o3SNF .checkbox_check_label__-ckXI {
  font-size: 0.85rem;
  margin-left: 1%;
  color: #707070;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/checkbox.module.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,wBAAwB;EACxB,6BAA6B;EAC7B,yBAAyB;EACzB;;kDAEgD;EAChD,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,UAAU;EACV,YAAY;EACZ,qBAAqB;EACrB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB","sourcesContent":["/* Checkbox */\n.aggreement_box {\n  margin-block: 1rem;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.aggreement_box input {\n  padding: 0;\n  height: initial;\n  width: initial;\n  margin-bottom: 0.5rem;\n  display: none;\n  cursor: pointer;\n}\n\n.aggreement_box label {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  font-size: 0.85rem;\n}\n\n.aggreement_box label:before {\n  content: \"\";\n  -webkit-appearance: none;\n  background-color: transparent;\n  border: 2px solid #ff8120;\n  box-shadow:\n    0 1px 2px rgba(0, 0, 0, 0.05),\n    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);\n  padding: 10px;\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n  cursor: pointer;\n  margin-right: 10px;\n}\n\n.aggreement_box input:checked + label:after {\n  content: \"\";\n  display: block;\n  position: absolute;\n  top: 5px;\n  left: 12px;\n  width: 6px;\n  height: 14px;\n  border: solid #ff8120;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}\n\n.aggreement_box .check_label {\n  font-size: 0.85rem;\n  margin-left: 1%;\n  color: #707070;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aggreement_box": `checkbox_aggreement_box__o3SNF`,
	"check_label": `checkbox_check_label__-ckXI`
};
export default ___CSS_LOADER_EXPORT___;
