import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./send-file-copy.module.css";
import { useEffect, useRef, useState } from "react";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import { errorToast, successToast } from "../../utils/notifications";
import axios from "axios";
import { getBaseUrl } from "../../constants/base-url";
import { isValidEmail } from "../../utils/validation";
import { HTTP_STATUS } from "../../constants/http-status";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import { format } from 'date-fns';

export function SendFileCopy() {
  const [params] = useSearchParams();
  const businessId = params.get("businessId");
  const { data, error, loading } = useFetchBusiness(businessId);
  const [sending, setSending] = useState(false);
  const [sign, setSign] = useState(null);
  const [user, setUser] = useState({
    fname: null,
    lname: null,
    mname: null,
  });

  useEffect(() => {
    extractSearchParams();
  }, []);

  function extractSearchParams() {
    params.forEach((value, key) => {
      if (key === "sign") setSign(value);
      if (key === "fname") setUser((p) => ({ ...p, fname: value }));
      if (key === "mname") setUser((p) => ({ ...p, mname: value }));
      if (key === "lname") setUser((p) => ({ ...p, lname: value }));
    });
  }

  const navigate = useNavigate();
  const emailRef = useRef();

  async function sendEmail() {
    try {
      const id = window?.localStorage?.getItem("businessId");
      if (!id) return errorToast(`Seems user haven't signed in before`);

      const email = emailRef.current.value;
      if (isValidEmail(email) === false) {
        return errorToast("Please provide a valid email");
      }

      let dateFormat = data.dateFormat === "DD/MM/YYYY" ? "dd/MM/yyyy" : data.dateFormat === "YYYY/MM/DD" ? "yyyy/MM/dd" :data.dateFormat === "MM-DD-YYYY" ? "MM/dd/yyyy" :data.dateFormat === "YYYY-MM-DD" ? "yyyy/MM/dd" :data.dateFormat === "MM.DD.YYYY" ? "MM/dd/yyyy" :data.dateFormat === "YYYY.MM.DD" ? "yyyy/MM/dd" :data.dateFormat === "DD.MM.YYYY" ? "dd/MM/yyyy" : "MM/dd/yyyy"

      setSending(true);
      const res = await axios.post(`${getBaseUrl()}/pwa/send-copy`, {
        businessId: JSON.parse(id),
        email,
        sign,
        user,
        date: format(new Date(), dateFormat),
      });
      setSending(false);

      if (res.status !== HTTP_STATUS.OK) {
        return errorToast("Something went wrong while sending");
      }

      successToast("File copy sent");

      if(data?.hideWaitingList) {
        return navigate('/');
      }else{
        navigeteToWaitingList();
      }
    } catch (error) {
      setSending(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  function navigeteToWaitingList() {
    // navigate to waitingList with the full name
    let name = user.fname;
    if (user.mname) name += " " + `${user.mname} `;
    if (user.lname) name += " " + `${user.lname} `;
    const linkToWaitingList = `/waiting-list?name=${name}`;
    navigate(linkToWaitingList);
  }

  function noThanks() {
    if(data?.hideWaitingList) {
      return navigate('/');
    }else{
      navigeteToWaitingList();
    }
  }

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        {/* Header */}
        <div className={classes.header}>
          <button className={classes.cross} onClick={() => navigate(-1)}>
            <img style={{ height: "1rem", width: "1rem" }} src="/x.svg" />
          </button>
        </div>

        <p className={classes.top_txt}>
          Enter email to get
          <p>copy of a file</p>
        </p>

        <div>
          <p className={classes.inp_title}>Email</p>
          <input ref={emailRef} />
        </div>
        <button
          disabled={sending}
          className={`${classes.fwbtn} ${classes.send_btn}`}
          onClick={sendEmail}
        >
          {sending ? "Sending..." : "Send"}
        </button>
        <button
          className={`${classes.fwbtn} ${classes.tq_btn}`}
          onClick={() => noThanks()}
        >
          No Thanks
        </button>
      </div>
    </main>
  );
}
