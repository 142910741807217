import { useState } from "react";
import classes from "./dial-pad.module.css";
//import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { validateNumbeByCountry } from "../../utils/validate-num-by-country";
import { errorToast } from "../../utils/notifications";
//import { IconMessage } from "@tabler/icons-react";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoTimerOutline } from "react-icons/io5";
//import { LuDelete } from "react-icons/lu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export function DialPad({ business, error }) {
  const [val, setVal] = useState("");
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  function handleBtnClick(num) {
    const numToStr = num.toString();
    const newVal = val + numToStr;

    if (newVal.length >= 4) setDisabled(false);
    else setDisabled(true);

    if (newVal.length > 10) return;
    setVal(newVal);
  }

  function handleBackClick() {
    if (!val) return;
    const newVal = val.slice(0, -1);

    if (newVal.length >= 5) setDisabled(false);
    else setDisabled(true);

    setVal(newVal);
  }

  function handleClearClick() {
    setDisabled(true);
    setVal("");
  }

  function handleKeyDown(event) {
    // Get the pressed key code
    const keyCode = event.which || event.keyCode;

    // Allow only numbers (0-9) and the backspace key (8)
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) return;

    let newVal;
    const keyString = String.fromCharCode(keyCode);

    // Backspace Clicked
    if (keyCode === 8) newVal = val.slice(0, -1);
    else newVal = val + keyString;

    if (newVal.length > 10) return;
    setVal(newVal);
    if (newVal.length >= 4) setDisabled(false);
    else setDisabled(true);
  }

  function handleNextClick() {
    // const valid = validateNumbeByCountry(business.country || null, val);
    // if (valid && valid?.error) return errorToast(valid.msg);
    if (val.length !== 10) return errorToast("Invalid Number");
    navigate(`/name-form/${val}`);
  }

  function handleMsgClick() {
    const num = localStorage.getItem("number");
    if (!num) return errorToast("No number found please login first");
    navigate(`/chat?number=${num}`);
  }

  if (error) return <p>{error}</p>;
  if (!business || !business.name) {
    return <p>No Business Found, Kindly Open the full Link again</p>;
  }

  // Split business name into words
  const words = business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" ").toLowerCase(); // Join the remaining words
  const currentDate = new Date();
  const dayNumber = currentDate.getDate();
  const daySuffix = getDaySuffix(dayNumber);
  const month = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  const day = currentDate.toLocaleString("default", { weekday: "long" }); // E.g., "Tue"

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    const lastDigit = day % 10;
    if (lastDigit === 1) return "st";
    if (lastDigit === 2) return "nd";
    if (lastDigit === 3) return "rd";
    return "th";
  }
  return (
    <div className="no-scroll">
      <div className={classes.page}>
        <div className={classes.pagescroll}>
          <div className={classes.header}>
            <div className={classes.containerBoxData}>
              <div className={classes.boxData}>
                {/*  12/04/2024 */}
                <div className={classes.month}>{month}</div>
                {/*  Day */}
                <div className={classes.day}>{day}</div>
              </div>
            </div>
            <div className={classes.businessName}>
              <span className={classes.firstWord}>{firstWord}</span>
              <span className={classes.restOfWords}>{restOfWords}</span>
            </div>
          </div>

          <div className={classes.container}>
            {/* Header */}

            <div className={classes.content}>
              {/* Dial Pad */}
              <div className={classes.log}>
                <div className={classes.input_sec}>
                  {/* <button className={classes.buttonLeft</div>} onClick={handleBackClick}>
                Back
              </button> */}
                  <div className={classes.dial_nums_input}>
                    <input
                      className={classes.num_input}
                      value={val}
                      placeholder="Enter your phone"
                      onKeyDown={handleKeyDown}
                      readOnly
                    />
                  </div>
                  {/* <button
                className={classes.buttonRight}
                onClick={handleClearClick}
              >
                Clear
              </button> */}
                </div>

                <div className={classes.dial_nums}>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>1</Btn>
                    <Btn onClick={handleBtnClick}>2</Btn>
                    <Btn onClick={handleBtnClick}>3</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>4</Btn>
                    <Btn onClick={handleBtnClick}>5</Btn>
                    <Btn onClick={handleBtnClick}>6</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>7</Btn>
                    <Btn onClick={handleBtnClick}>8</Btn>
                    <Btn onClick={handleBtnClick}>9</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn
                      onClick={handleBackClick}
                      id="btnBack"
                      className={classes.btnBack}
                    >
                      Back
                    </Btn>
                    <Btn onClick={handleBtnClick}>0</Btn>
                    <Btn
                      onClick={handleClearClick}
                      id="btnClear"
                      className={classes.btnClear}
                    >
                      Clear
                    </Btn>
                  </div>
                </div>

                <div className={classes.footer1}>
                  <div className={classes.footer}>
                    <button
                      onClick={() => navigate("/share")}
                      className={classes.footerIcon}
                    >
                      <IoShareSocialOutline size={"2rem"} color="#ff8120" />
                    </button>
                    <button
                      className={classes.next_btn}
                      disabled={disabled}
                      onClick={handleNextClick}
                    >
                      Next <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                    {!business.hideWaitingList && (
                      <button
                        onClick={() => navigate("/waiting-list")}
                        className={classes.footerIcon}
                      >
                        <IoTimerOutline size={"2rem"} color="#ff8120" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* 
      ---------------------------
          Bottom Two Buttons
      ---------------------------
      */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Btn({ children, onClick }) {
  function handleClick() {
    onClick(children);
  }

  return (
    <button onClick={handleClick} className={classes.dial_btn}>
      {children}
    </button>
  );
}
