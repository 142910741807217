import { useState } from "react";
import classes from "./dial-pad.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { validateNumbeByCountry } from "../../utils/validate-num-by-country";
import { errorToast } from "../../../utils/notifications";
import { IconShare } from "@tabler/icons-react";
import { IoTimerOutline } from "react-icons/io5";

export function DialPadOriginal({ business, error }) {
  const [val, setVal] = useState("");
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  function handleBtnClick(num) {
    const numToStr = num.toString();
    const newVal = val + numToStr;

    if (newVal.length >= 4) setDisabled(false);
    else setDisabled(true);

    if (newVal.length > 10) return;
    setVal(newVal);
  }

  function handleBackClick() {
    if (!val) return;
    const newVal = val.slice(0, -1);

    if (newVal.length >= 5) setDisabled(false);
    else setDisabled(true);

    setVal(newVal);
  }

  function handleClearClick() {
    setDisabled(true);
    setVal("");
  }

  function handleKeyDown(event) {
    // Get the pressed key code
    const keyCode = event.which || event.keyCode;

    // Allow only numbers (0-9) and the backspace key (8)
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) return;

    let newVal;
    const keyString = String.fromCharCode(keyCode);

    // Backspace Clicked
    if (keyCode === 8) newVal = val.slice(0, -1);
    else newVal = val + keyString;

    if (newVal.length > 10) return;
    setVal(newVal);
    if (newVal.length >= 4) setDisabled(false);
    else setDisabled(true);
  }

  function handleNextClick() {
    // const valid = validateNumbeByCountry(business.country || null, val);
    // if (valid && valid?.error) return errorToast(valid.msg);
    if (val.length !== 10) return errorToast("Invalid Number");
    navigate(`/name-form/${val}`);
  }

  function handleMsgClick() {
    const num = localStorage.getItem("number");
    if (!num) return errorToast("No number found please login first");
    navigate(`/chat?number=${num}`);
  }

  if (error) return <p>{error}</p>;
  if (!business || !business.name) {
    return <p>No Business Found, Kindly Open the full Link again</p>;
  }

  // Split business name into words
  const words = business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" "); // Join the remaining words
  const image = business?.businessProfile;

  return (
    
    <div className={classes.body_wrapper}> 
      <div className={classes.container}>
        {/* Header */}
        <div className={classes.date_time}>
          <p className={classes.timeStyle}>
            {moment().format(business?.dateFormat || "MM/DD/YYYY")}
          </p>
          {image && <img className={classes.imageUser} src={image} />}
          {/* <p className={classes.iconImage}>
              <IconUser width={30} color="white" height={30} />
            </p> */}

          {/* <Image /> */}
          {/* {business?.timeFormat === "12H" || business?.timeFormat === "24H" ? (
            <p className={classes.timeStyle}>{moment().format("HH:MM A")}</p>
          ) : (
            <>
              <p className={classes.timeStyle}>
                {moment().format(business?.timeFormat || "HH:MM A")}
              </p>
            </>
          )} */}
          <div className={classes.header_sec}>
            <button
              onClick={() => navigate("/share")}
              className={classes.share_btn}
              style={{ margin: business.hideWaitingList ? "0 auto" : "" }} // Dynamically center
            >
              <IconShare color="#ff8120" size={24} />
            </button>
            {!business.hideWaitingList ? (
              <button onClick={() => navigate("/waiting-list")}>
                <IoTimerOutline size={24} color="#ff8120" />
              </button>
            ) : null} {/* Remove empty button */}
          </div>

          
        </div>

        {/* Title */}
        <div className={classes.heading}>
          <div className={classes.heading}>
            {firstWord} <span>{restOfWords}</span>
          </div>
        </div>

        {/* Dial Pad */}
        <div className={classes.log}>
          <div className={classes.dial_nums_input}>
      
              <input
                className={classes.num_input}
                value={val}
                onKeyDown={handleKeyDown}
              />
          </div>
          {/* <div className={classes.input_sec}>
            <button onClick={handleBackClick}>Back</button>
          
            <button onClick={handleClearClick}>Clear</button>
          </div> */}

          <div className={classes.dial_nums}>
            <div className={classes.row}>
              <Btn onClick={handleBtnClick}>1</Btn>
              <Btn onClick={handleBtnClick}>2</Btn>
              <Btn onClick={handleBtnClick}>3</Btn>
            </div>
            <div className={classes.row}>
              <Btn onClick={handleBtnClick}>4</Btn>
              <Btn onClick={handleBtnClick}>5</Btn>
              <Btn onClick={handleBtnClick}>6</Btn>
            </div>
            <div className={classes.row}>
              <Btn onClick={handleBtnClick}>7</Btn>
              <Btn onClick={handleBtnClick}>8</Btn>
              <Btn onClick={handleBtnClick}>9</Btn>
            </div>
            <div className={classes.row}>
              {/*  <Btn onClick={() => navigate("/share")}>
                <div
                  style={{
                    boxSizing: "content-box",
                    display: "contents",
                  }}
                >
                  <IconShare size={24} />
                </div>
              </Btn> */}
              <Btn onClick={handleBackClick} className={classes.back_btn}>Back</Btn>
              <Btn onClick={handleBtnClick}>0</Btn>
              <Btn onClick={handleClearClick} className={classes.clear_btn}>Clear</Btn>
            </div>
          </div>
          {/* buttons */}
          <div className={classes.button_sec}>
          
            <div className={classes.next_btn}>
              <button
                disabled={disabled}
                onClick={handleNextClick}
                style={{
                  cursor: "pointer",
                  backgroundColor: disabled ? "#ff8120" : "#ff8120",
                  //color: disabled ? "#1d1d1d" : "#fff",
                  color: "#fff",
                  padding: "10px 20px",
                }}
              >
                Next
              </button>

              {/* <IconMessage size={15} onClick={handleMsgClick} /> */}
            </div>
            
          </div>
        </div>

        {/* 
        ---------------------------
            Bottom Two Buttons
        ---------------------------
        */}
        <div className={classes.footer}>
          {/* <button onClick={() => navigate("/share")}>Share</button> */}
          {/* {!business.hideWaitingList && (
            <button onClick={() => navigate("/waiting-list")}>
              Waiting List
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
}

function Btn({ children, onClick }) {
  function handleClick() {
    onClick(children);
  }

  return (
    <button onClick={handleClick} className={classes.dial_btn}>
      {children}
    </button>
  );
}
