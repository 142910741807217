import axios from "axios";
import { useEffect, useState } from "react";
import { HTTP_STATUS } from "../constants/http-status";
import { getMsgFromAxiosErr } from "../utils/get-axios-err-msg";
import { getBaseUrl } from "../constants/base-url";

export function useFetchBusiness(id) {
  const [data, setData] = useState();
  const [error, setErr] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBusiness();
  }, [id]);

  async function fetchBusiness() {
    try {
      const ID = id || getId();

      if (!ID) {
        setLoading(false);
        return setErr("No Business found, Kindly open the full link again");
      }

      const res = await axios.get(`${getBaseUrl()}/business/${ID}`);
      setLoading(false);

      if (res.status !== HTTP_STATUS.OK) {
        setErr(res?.data?.msg || res?.data?.message || "Something wen't wrong");
      } else {
        setData({
          ...res.data.business,
          file: res.data?.file,
          fields: res.data?.fields,
          sheet: res.data?.sheet,
          businessType: res.data?.businessType,
          businessProfile: res.data?.businessProfile,
        });

        saveIdInStorage(res.data?.business?._id);
      }
    } catch (error) {
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      setErr(msg);
    }
  }

  async function saveIdInStorage(id) {
    try {
      window?.localStorage?.setItem("businessId", JSON.stringify(id));
    } catch (error) {
      console.log("Somehting wen't wrong");
    }
  }

  function getId() {
    try {
      const id = window?.localStorage?.getItem("businessId");
      let res;
      if (id) res = JSON.parse(id);
      else res = id;

      return res;
    } catch (error) {
      console.log("Opps!");
    }
  }

  return { data, error, loading };
}
