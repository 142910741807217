// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr_x_header__hsV\\+H {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #fff;
    /* Dropshadow/md */
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }
}

.qr_log__VsfuM {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  button {
    font-family: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/share/qr.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;;EAErB;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB;;4CAEwC;EAC1C;AACF;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,SAAS;;EAET;IACE,oBAAoB;IACpB,eAAe;IACf,6BAA6B;IAC7B,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".x_header {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 0.5rem;\n\n  button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 1.5rem;\n    height: 1.5rem;\n    border-radius: 50%;\n    outline: none;\n    border: none;\n    cursor: pointer;\n    background-color: #fff;\n    /* Dropshadow/md */\n    box-shadow:\n      0px 2px 4px -2px rgba(16, 24, 40, 0.06),\n      0px 4px 8px -2px rgba(16, 24, 40, 0.1);\n  }\n}\n\n.log {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 1rem;\n\n  button {\n    font-family: inherit;\n    cursor: pointer;\n    background-color: transparent;\n    border: none;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"x_header": `qr_x_header__hsV+H`,
	"log": `qr_log__VsfuM`
};
export default ___CSS_LOADER_EXPORT___;
