import { useRef } from "react";
import classes from "./sign-form.module.css";
import SignatureCanvas from "react-signature-canvas";
import { useScreenSize } from "../../hooks/use-screen-size";

export function SignForm({ onSave, onClose }) {
  const signRef = useRef();
  const isSmall = useScreenSize(530);
  const is_350 = useScreenSize(350);

  async function handleSaveClick() {
    let canvas = signRef.current.getCanvas(); // Access the canvas element

    if (isSmall) {
      const rotatedCanvas = document.createElement("canvas");
      const ctx = rotatedCanvas.getContext("2d");

      // Set the dimensions of the new canvas to accommodate the rotation
      rotatedCanvas.width = canvas.height;
      rotatedCanvas.height = canvas.width;

      // Rotate and draw the original image onto the new canvas
      ctx.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);
      ctx.rotate((-90 * Math.PI) / 180);
      ctx.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);

      canvas = rotatedCanvas;
    }

    let blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    onSave(blob);
  }

  function handleClear() {
    signRef.current.clear();
  }

  return (
    <div className={classes.modal}>
      <div className={classes.container}>
        <button className={classes.close_btn} onClick={onClose}>
          <img style={{ height: "1rem", width: "1rem" }} src="/x.svg" />
        </button>
        <SignatureCanvas
          velocityFilterWeight={1}
          minDistance={0.001}
          ref={signRef}
          canvasProps={{
            style: { backgroundColor: "#FDF3F3" },
            width: isSmall ? 350 : 500,
            height: isSmall ? 600 : 300,
            className: classes.sigCanvas,
          }}
        />
        <div className={classes.bottom_div}>
          <button className={`${classes.btn}`} onClick={handleClear}>
            Clear
          </button>
          <button
            onClick={handleSaveClick}
            className={`${classes.btn}`}
            style={{ color: "#FF8120" }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
