import { useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./home.module.css";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import { getBaseUrl } from "../../constants/base-url";
import { DialPad } from "../../components/dial-pad/dial-pad"; // resturant
import { DialPadOriginal } from "../../components/dial-pad/original/dial-pad"; // original

export function HomePage() {
  const { businessId } = useParams();
  const { data, error, loading } = useFetchBusiness(businessId);

  useEffect(() => {
    if (businessId) {
      const manifestLink = document.createElement("link");
      manifestLink.rel = "manifest";
      manifestLink.href = `${getBaseUrl()}/manifest.json?businessId=${businessId}`;
      document.head.appendChild(manifestLink);

      return () => {
        document.head.removeChild(manifestLink);
      };
    }
  }, [businessId]);

  let firstWord = "";
  let restOfWords = "";
  const businessType = data?.businessType || "original";

  if (data?.name) {
    const words = data.name.split(" ");
    firstWord = words.shift(); // Remove the first word
    restOfWords = words.join(" "); // Join the remaining words
  }

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {businessType === "original" ? (
            <DialPadOriginal business={data} />
          ) : (
            <DialPad business={data} />
          )}
        </div>
      )}
    </main>
  );
}
