import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DialPad } from "../../components/dial-pad/dial-pad"; // resturant
import { DialPadOriginal } from "../../components/dial-pad/original/dial-pad"; // original
import classes from "./login-paid.module.css";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import { getBaseUrl } from "../../constants/base-url";

export function LoginPaidPage() {
  const { businessId } = useParams();
  const { data, error, loading } = useFetchBusiness(businessId);

  const businessType = data?.businessType || "original";

  useEffect(() => {
    const manifestLink = document.createElement("link");
    manifestLink.rel = "manifest";
    manifestLink.href = `${getBaseUrl()}/manifest.json?businessId=${businessId}`;
    document.head.appendChild(manifestLink);

    return () => {
      document.head.removeChild(manifestLink);
    };
  }, [businessId]);

  return (
    <main className={classes.main}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : businessType === "original" ? (
        <DialPadOriginal business={data} />
      ) : (
        <DialPad business={data} />
      )}
    </main>
  );
}
